import { cn } from '#app/utils/misc'

interface SeparatorLineProps extends React.HTMLAttributes<HTMLDivElement> {
	className?: string
}
const SeparatorLine = ({ className, ...rest }: SeparatorLineProps) => {
	return (
		<div className={cn('h-[1px] w-full bg-beige-70', className)} {...rest} />
	)
}

export default SeparatorLine
